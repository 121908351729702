<script>
import Layout from "../../layouts/main";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {};
  },
};
</script>

<template>
  <layout>
    <b-col>
      <b-row> 
        <b-col>
          <b-row>
            <b-col ><i class=" ri-bubble-chart-fill" style="color:green;font-size:22px;"></i></b-col>
            <b-col><h6>Present : 0</h6></b-col>
            <b-col><h6>Days</h6></b-col>
          </b-row>
        </b-col> 
      </b-row> 
      <b-row> 
        <b-col><hr></b-col> 
      </b-row>
      <b-row> 
        <b-col>
          <b-row>
            <b-col ><i class=" ri-bubble-chart-fill" style="color:red;font-size:22px;"></i></b-col>
            <b-col><h6>Absent : 0</h6></b-col>
            <b-col><h6>Days</h6></b-col>
          </b-row>
        </b-col> 
      </b-row> 
    </b-col>
  </layout>
</template>
